/*Portfolio.css*/

/*Container for colored subject tags*/
.tags {
	display: flex;
	flex-wrap: wrap;
	gap: 0.1rem 0.8rem;
	margin: 0.7rem 0 0 0;
	font-weight: 500;
}
/*Subject tag*/
.tag {
	background-color: var(--lightblue);
	padding: 0.1rem 0.8rem;
	color: black;
	border-radius: 0.9rem;
	font-size: 0.85rem;
}
/*Year tag*/
.year {
	border: 1px var(--light) solid;
	color: var(--dark);
	background-color: var(--light);
	padding: 0.1rem 0.3rem;
	border-radius: 0;

}

#portfolio .row {
	padding: 6rem 0 3rem 0;
}
#portfolio .intro.row {
	padding: 0;
}
#portfolio .row:nth-child(3) {
	padding: 2rem 0 3rem 0;
}
#portfolio .intro {
	max-width: 65ch;
}

/*Separating line*/
.line {
	background-image: url("./../../images/line_straighter_400.svg");
	background-size: cover;
	width: 100%;
	height: 25px;
	opacity: 80%;
}

@media (max-width: 400px) {
	#portfolio .row {
		padding: 3rem 0 1.5rem 0;
	}

	#portfolio .row:nth-child(3) {
		padding: 1rem 0 1.5rem 0;
	}
}
